import { trimStart } from 'lodash';
import { WaffleFlags } from '@/domain/WaffleFlags.enum';

/**
 * Checks if the flag exists in the environment
 * @param waffleFlag {string} - The waffle flag to look for in the env.
 * @returns {*}
 */
const isFlagInEnv = (waffleFlag: WaffleFlags): boolean => {
  if (process.env.VUE_APP_WAFFLE_FLAGS) {
    const waffleFlags = process.env.VUE_APP_WAFFLE_FLAGS.split(',').map((flagString: WaffleFlags) => trimStart(flagString));
    return waffleFlags.includes(waffleFlag);
  }
  return false;
};

const waffleFlagService = (() => {
  let accountWaffleFlags: { accountId: string | null, waffleFlags: Record<WaffleFlags, boolean> | {} } = {
    accountId: null,
    waffleFlags: {}
  };

  return {
    setWaffleFlags(accountId: string | null, waffleFlags: Record<WaffleFlags, boolean>) {
      accountWaffleFlags = {
        accountId,
        waffleFlags
      };
    },
    checkWaffleFlag(waffleFlagName: WaffleFlags): boolean {
      if (process.env.VUE_APP_OVERRIDE_WAFFLE_FLAGS === 'true') return isFlagInEnv(waffleFlagName);
      return !!accountWaffleFlags.waffleFlags[waffleFlagName];
    },
    /**
     * A feature flag for querying tickservice for unit locations
     * @returns {boolean} The enabled/disabled state of the flag
     */
    unitLocationsFromTickService(): boolean {
      const waffleFlag = WaffleFlags.UnitLocationsFromTickService;
      return this.checkWaffleFlag(waffleFlag);
    },
    /**
     * A feature flag for enabling live mode
     * @returns {boolean} The enabled/disabled state of the flag
     */
    isLiveModeEnabled(): boolean {
      const waffleFlag = WaffleFlags.IsLiveModeEnabled;
      return this.checkWaffleFlag(waffleFlag);
    },
    isOddjobEnabled(): boolean {
      const waffleFlag = WaffleFlags.IsOddjobEnabled;
      return this.checkWaffleFlag(waffleFlag);
    },
    isMoneyPennyEnabled(): boolean {
      const waffleFlag = WaffleFlags.IsMoneyPennyEnabled;
      return this.checkWaffleFlag(waffleFlag);
    },
    isSurfaceToModelComparisonEnabled(): boolean {
      const waffleFlag = WaffleFlags.IsSurfaceToModelComparisonEnabled;
      return this.checkWaffleFlag(waffleFlag);
    },
    isEditedAndDeletedMeasuredPointsEnabled(): boolean {
      const waffleFlag = WaffleFlags.IsEditedAndDeletedMeasuredPointsEnabled;
      return this.checkWaffleFlag(waffleFlag);
    },
    showSurfacelogDeltaHeightLegendConfigurationUi2(): boolean {
      const waffleFlag = WaffleFlags.ShowSurfacelogDeltaHeightLegendConfigurationUi2;
      return this.checkWaffleFlag(waffleFlag);
    },
    compressedSignalData(): boolean {
      const waffleFlag = WaffleFlags.CompressedSignalData;
      return this.checkWaffleFlag(waffleFlag);
    },
    showSurfacelogVolumeCalculations(): boolean {
      const waffleFlag = WaffleFlags.ShowSurfacelogVolumeCalculations;
      return this.checkWaffleFlag(waffleFlag);
    },
    filteringSurfacesByPointCodes(): boolean {
      const waffleFlag = WaffleFlags.FilteringSurfacesByPointCodes;
      return this.checkWaffleFlag(waffleFlag);
    },
    showJobsInConX(): boolean {
      const waffleFlag = WaffleFlags.ShowJobsInConX;
      return this.checkWaffleFlag(waffleFlag);
    },
    showEnterpriseReporting(): boolean {
      const waffleFlag = WaffleFlags.EnterpriseReporting;
      return this.checkWaffleFlag(waffleFlag);
    },
    isBukaEnabled(): boolean {
      const waffleFlag = WaffleFlags.IsBukaEnabled;
      return this.checkWaffleFlag(waffleFlag);
    }
  };
})();
export default waffleFlagService;