import waffleFlagService from '@/services/waffleFlag.service';
import { mapGetters } from 'vuex';

const CxWaffleFlagMixin = {
  computed: {
    ...mapGetters('app', ['projectInitialized']),
    isLiveModeEnabled() {
      if (!this.projectInitialized) return false;
      return waffleFlagService.isLiveModeEnabled();
    },
    unitLocationsFromTickService() {
      if (!this.projectInitialized) return false;
      return waffleFlagService.unitLocationsFromTickService();
    },
    isOddjobEnabled() {
      if (!this.projectInitialized) return false;
      return waffleFlagService.isOddjobEnabled();
    },
    isMoneyPennyEnabled() {
      if (!this.projectInitialized) return false;
      return waffleFlagService.isMoneyPennyEnabled();
    },
    isSurfaceToModelComparisonEnabled() {
      if (!this.projectInitialized) return false;
      return waffleFlagService.isSurfaceToModelComparisonEnabled();
    },
    isEditedAndDeletedMeasuredPointsEnabled() {
      if (!this.projectInitialized) return false;
      return waffleFlagService.isEditedAndDeletedMeasuredPointsEnabled();
    },
    showSurfacelogDeltaHeightLegendConfigurationUi2() {
      if (!this.projectInitialized) return false;
      return waffleFlagService.showSurfacelogDeltaHeightLegendConfigurationUi2();
    },
    showSurfacelogVolumeCalculations() {
      if (!this.projectInitialized) return false;
      return waffleFlagService.showSurfacelogVolumeCalculations();
    },
    filteringSurfacesByPointCodes() {
      if (!this.projectInitialized) return false;
      return waffleFlagService.filteringSurfacesByPointCodes();
    },
    showEnterpriseReporting() {
      if (!this.projectInitialized) return false;
      return waffleFlagService.showEnterpriseReporting();
    }
  },
};

export default CxWaffleFlagMixin;
