<template>
  <div class="cx-filter-awareness-event-view">
    <transition :name="applyAnimations('filter1')">
      <cx-filter-title v-show="isAnimationReady" :title="translations.title">
        <cx-button
          v-if="showEnterpriseReporting"
          :variantPrimary="true"
          :loading="enterpriseReportingLoading"
          @click="openEnterpriseReportingPage">
          {{ translations.enterpriseReporting }}
        </cx-button>
      </cx-filter-title>
    </transition>
    <transition :name="applyAnimations('filter2')">
      <div class="cxu-mt-32">
        <cx-toggle-switch
          :checked="isKpiPopupOpen"
          :onToggle="() => openPopup('popup-awareness-kpi')"
          :label="translations.dashboard"/>
      </div>
    </transition>
    <transition :name="applyAnimations('filter3')">
      <div class="cx-filter-awareness-event-view__time-range-filter" v-show="isAnimationReady">
        <cx-filter-title :title="translations.alarmOccurrenceTime" :rank="3"/>
        <cx-time-range-filter
          :startDate="startDate"
          :changeStartDate="setStartDate"
          :endDate="endDate"
          :changeEndDate="setEndDate"
          :projectStartDay="projectStartDay"/>
      </div>
    </transition>
    <transition :name="applyAnimations('filter4')">
      <div class="cx-filter-awareness-event-view__main-filters" v-show="isAnimationReady">
        <cx-filter-title :title="translations.mainFilters" :rank="3"/>
        <cx-combobox
          :items="availableAlarmTypes"
          :value="selectedAlarmTypes"
          :input="setSelectedAlarmTypes"
          :label="translations.alarmTypeFilter"
          isChips
          isTextInput
          isDropdown/>
        <cx-unit-type-filter
          :availableUnitTypes="availableUnitTypes"
          :selectedUnitTypes="selectedUnitTypes"
          :changeSelectedUnitTypes="setSelectedUnitTypes"/>
        <cx-combobox
          :items="availableUnitNames"
          :value="selectedUnitNames"
          :input="setSelectedUnitNames"
          :label="translations.specificUnitFilter"
          isChips
          isTextInput
          isDropdown/>
        <cx-combobox
          :items="availableDetectedZones"
          :value="selectedDetectedZones"
          :input="setSelectedDetectedZones"
          :label="translations.detectedZoneFilter"
          isChips
          isTextInput
          isDropdown/>
      </div>
    </transition>
    <transition :name="applyAnimations('filter5')">
      <div class="cx-filter-awareness-event-view__alarm-status-filter" v-show="isAnimationReady">
        <cx-filter-title :title="translations.alarmStatusFilter" :rank="3"/>
        <cx-multiselect-bar
          :value="selectedAlarmStatuses"
          @onSelectedChange="setSelectedAlarmStatus"
          @onReset="clearSelectedAlarmStatuses"/>
      </div>
    </transition>
    <transition :name="applyAnimations('filter6')">
      <cx-list :items="results" :header="listHeader" v-show="isAnimationReady">
        <template #default="{ item }">
          <cx-list-item :item="item" :disabled="true">
            <template #selection><div></div></template>
            <template #before-content="{}">
              <cx-icon class="cxu-color-faded" icon="warning" :size="15"/>
            </template>
          </cx-list-item>
        </template>
        <template #header-append="{}">
          <cx-warning :warning="translations.warningHeader" v-if="awarenessEventSet.exceedsPerformanceLimit"/>
        </template>
        <template #placeholder>
          <cx-label class="cx-simple-list__placeholder" :label="translations.placeholder"/>
        </template>
      </cx-list>
    </transition>
    <transition :name="applyAnimations('action')">
      <cx-filter-action-panel
        v-show="isAnimationReady"
        :onClearFiltersClicked="clearFilters"
        :onApplyClicked="filterAwarenessEvents"
        :isDisabled="!datesValid"
        :queryOngoing="isQueryOngoing">
        <cx-menu-button
          :text="translations.download"
          :items="[{name: 'CSV', disabled: !resultAwarenessEventSet.length}, {name: 'Power BI Template'}]"
          class="cx-filter-action-panel__button--export "
          :loading="isExportLoading"
          menuPosition="top"
          @click="handleDownload"/>
      </cx-filter-action-panel>
    </transition>
  </div>
</template>

<script>
import { isBefore } from 'date-fns';
import { mapGetters, mapMutations, mapActions } from 'vuex';
import CxAnimationMixin from '@/mixins/CxAnimation.mixin.js';
import CxFilterTitle from '@/app/filterPanel/common/CxFilterTitle';
import CxTimeRangeFilter from '@/app/filterPanel/common/CxTimeRangeFilter';
import CxCombobox from '@/components/cxCombobox/CxCombobox';
import CxButton from '@/components/CxButton.vue';
import CxUnitTypeFilter from '@/app/filterPanel/common/CxUnitTypeFilter';
import CxMultiselectBar from '@/components/CxMultiselectBar';
import CxList from '@/components/list/CxList';
import CxListItem from '@/components/list/CxListItem';
import CxIcon from '@/components/CxIcon';
import CxLabel from '@/components/CxLabel';
import CxFilterActionPanel from '@/app/filterPanel/common/CxFilterActionPanel';
import CxMenuButton from '@/components/menuComponents/CxMenuButton';
import CxToggleSwitch from '@/components/CxToggleSwitch';
import CxWaffleFlagMixin from '@/mixins/CxWaffleFlag.mixin';
import CxWarning from '@/components/CxWarning';
import { gettext } from '@/translations/gettext.setup';

export default {
  name: 'CxFilterAwarenessEventView',
  components: {
    CxFilterTitle,
    CxTimeRangeFilter,
    CxCombobox,
    CxButton,
    CxUnitTypeFilter,
    CxMultiselectBar,
    CxList,
    CxListItem,
    CxIcon,
    CxLabel,
    CxFilterActionPanel,
    CxMenuButton,
    CxWarning,
    CxToggleSwitch
  },
  mixins: [CxAnimationMixin, CxWaffleFlagMixin],
  data() {
    return {
      enterpriseReportingLoading: false
    };
  },
  computed: {
    ...mapGetters('filterAwarenessEvents', [
      'startDate',
      'endDate',
      'availableAlarmTypes',
      'availableUnitTypes',
      'availableUnitNames',
      'availableDetectedZones',
      'selectedAlarmTypes',
      'selectedUnitTypes',
      'selectedUnitNames',
      'selectedDetectedZones',
      'selectedAlarmStatuses',
      'resultAwarenessEventSet',
      'isQueryOngoing',
      'isExportLoading',
    ]),
    ...mapGetters('app', ['projectStartDay']),
    ...mapGetters('popup', ['isOpened']),
    ...mapGetters('map', ['maxLoadableEventsCount']),
    datesValid() {
      return isBefore(this.startDate, this.endDate);
    },
    awarenessEventSet() {
      if (this.resultAwarenessEventSet.length) return this.resultAwarenessEventSet[0].data;
      return null;
    },
    results() {
      if (!this.resultAwarenessEventSet.length) return [];
      return this.resultAwarenessEventSet.map(r => ({ id: r.id, title: r.name }));
    },
    listHeader() {
      return this.results.length ? this.translations.result : this.translations.noResult;
    },
    translations() {
      return {
        title: this.$gettext('Awareness Events'),
        enterpriseReporting: this.$gettext('Enterprise Reporting'),
        alarmOccurrenceTime: this.$gettext('Alarm Occurrence Time'),
        mainFilters: this.$gettext('Main Filters'),
        alarmTypeFilter: this.$gettext('Alarm Types'),
        specificUnitFilter: this.$gettext('Specific Units'),
        detectedZoneFilter: this.$gettext('Detected Zones'),
        alarmStatusFilter: this.$gettext('Alarm Status'),
        download: this.$gettext('Save Data As...'),
        result: this.$gettext('Results'),
        placeholder: this.$gettext('No results'),
        dashboard: this.$gettext('KPI Dashboard'),
        warningHeader: this.getWarningHeader()
      };
    },
    isKpiPopupOpen() {
      return this.isOpened('popup-awareness-kpi');
    }
  },
  methods: {
    ...mapMutations('filterAwarenessEvents', [
      'setStartDate',
      'setEndDate',
      'setSelectedAlarmTypes',
      'setSelectedUnitTypes',
      'setSelectedUnitNames',
      'setSelectedDetectedZones',
      'setSelectedAlarmStatus',
      'clearSelectedAlarmStatuses',
      'clearFilters'
    ]),
    ...mapActions('filterAwarenessEvents', ['filterAwarenessEvents', 'exportAwarenessEvents', 'downloadPowerBiTemplate']),
    ...mapActions('popup', ['openPopup']),
    ...mapActions('shell', ['openEnterpriseReporting']),
    async openEnterpriseReportingPage() {
      this.enterpriseReportingLoading = true;
      await this.openEnterpriseReporting();
      this.enterpriseReportingLoading = false;
    },
    handleDownload(downloadOption) {
      if (downloadOption.name === 'CSV') this.exportAwarenessEvents();
      else this.downloadPowerBiTemplate();
    },
    getWarningHeader() {
      if (!this.awarenessEventSet) return null;
      if (!this.awarenessEventSet.exceedsPerformanceLimit) return null;
      const title = this.$gettext('Unable to load %{ totalCount } awareness events because they exceed the performance limit (%{ max }).');
      return gettext.gettextInterpolate(title, {
        totalCount: this.awarenessEventSet.totalCount,
        max: this.awarenessEventSet.count
      });
    }
  }
};
</script>

<style lang="less">
@import '../../../common';

.cx-filter-awareness-event-view {
  height: 100%;
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: scroll;
  padding: @inputSpace 10px 100px 0;
  font-size: @appFontSize;

  .cx-filter-title {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .cx-filter-awareness-event-view__time-range-filter {
    margin-top: @inputHeight;
  }

  .cx-filter-awareness-event-view__alarm-status-filter {
    margin-top: 10px;

    .cx-multiselect-bar {
      padding-top: 10px;

      .cx-checkbox {
        width: 45%;
        justify-content: center;
      }
    }
  }

  .cx-simple-list {
    padding-top: 20px;

    .cx-simple-list__placeholder {
      text-align: left;
      margin-left: 10px;
    }
  }

  .cx-filter-action-panel__button--export {
    margin: auto;
    margin-right: 0;
    .cx-menu.top {
      top: -85px;
    }
  }
}
</style>
