import contextLicenceService from '@/services/contextLicence.service';
import licenceService from '@/services/licence.service';
import { withContext } from '@/domain/entityMixins';
import { UnitTypes } from '@/domain/units/UnitTypes.enum';
import { RefmodelTypes } from '@/domain/refmodels/RefmodelTypes.enum';
import { PointTypes } from '@/domain/point/PointTypes.enum';
import { PointSetType } from '@/domain/pointSet/PointSetType';
import { SurfacelogType } from '@/domain/surfacelog/SurfacelogType';
import { StabilizationTypes } from '@/domain/hole/StabilizationTypes.enum';
import { DrillHoleSetType } from '@/domain/holeSet/DrillHoleSetType';
import { AwarenessEventType } from '@/domain/awareness/AwarenessEventType';
import { AwarenessEventSetType } from '@/domain/awareness/AwarenessEventSetType';
import { getEnterpriseReportingURL } from '@/http/enterpriseReporting.api';
import { gettext } from '@/translations/gettext.setup';

const { $gettext } = gettext;

const filterPanelTypes = {
  dashboard: 'dashboardView',
  unit: 'CxFilterUnitView',
  measurePoint: 'CxAsBuiltFilterContainer',
  refModel: 'CxFilterRefModelView',
  awarenessEvent: 'CxFilterAwarenessEventView'
};

const filterPointPanelTypes = {
  point: 'CxMeasuredPointFilters',
  hole: 'CxDrillHoleFilters',
  surface: 'CxSurfaceFilters'
};

const detailsPanelTypes = {
  unit: 'CxUnitDetailsPanel',
  point: 'CxMeasuredPointDetailsPanel',
  refModel: 'CxRefModelDetailsPanel',
  pointSet: 'CxPointSetDetailsPanel',
  hole: 'CxHoleDetailsPanel',
  holeSet: 'CxHoleSetDetailsPanel',
  drillPattern: 'CxDrillPatternDetailsPanel',
  awarenessEventSet: 'CxAwarenessEventSetDetailsPanel',
  awarenessEvent: 'CxAwarenessEventDetailsPanel',
  surfaceLog: 'CxSurfaceLogDetailsPanel'
};

const shellStoreModule = {
  state: {
    isFilterPanelOpened: false,
    isDetailsPanelOpened: false,
    isObjectExplorerOpened: false,
    filterPanelContent: null,
    detailsPanelType: null,
    isDetailsPanelReduced: false,
    filterPointPanelContent: null,
    isEnterpriseReportingOpened: false,
    enterpriseReportingUrl: null,
    animationSwitch: {
      isFilterPanelTransitions: true,
      isDetailsPanelTransitions: true,
      isObjectExplorerTransitions: true,
      isAvatarTransitions: true,
      isLegendTransitions: true,
      isDirectSearchTransitions: true,
      isMapToolbarTransitions: true
    }
  },
  getters: {
    isFilterPanelOpened(state) {
      return state.isFilterPanelOpened;
    },
    isDetailsPanelOpened(state) { // should be only a getter and be synced with selected entity
      return state.isDetailsPanelOpened;
    },
    isObjectExplorerOpened(state) {
      return state.isObjectExplorerOpened;
    },
    filterPanelContent(state) {
      return state.filterPanelContent;
    },
    detailsPanelType(state) {
      return state.detailsPanelType;
    },
    isDetailsPanelReduced(state) {
      return state.isDetailsPanelReduced;
    },
    filterPointPanelContent(state) {
      return state.filterPointPanelContent;
    },
    isEnterpriseReportingOpened(state) {
      return state.isEnterpriseReportingOpened;
    },
    enterpriseReportingUrl(state) {
      return state.enterpriseReportingUrl;
    },
    animationSwitch(state) {
      return state.animationSwitch;
    }
  },
  mutations: {
    setIsFilterPanelOpened(state, isOpened) {
      state.isFilterPanelOpened = isOpened;
    },
    setFilterPanelContent(state, content) {
      state.filterPanelContent = content;
    },
    setIsDetailsPanelOpened(state, isOpened) {
      state.isDetailsPanelOpened = isOpened;
    },
    setIsObjectExplorerOpened(state, payload) {
      state.isObjectExplorerOpened = payload;
    },
    setDetailsPanelType(state, type) {
      state.detailsPanelType = type;
    },
    setIsDetailsPanelReduced(state, isReduced) {
      state.isDetailsPanelReduced = isReduced;
    },
    setFilterPointPanelContent(state, content) {
      state.filterPointPanelContent = content;
    },
    setIsEnterpriseReportingOpened(state, isOpened) {
      state.isEnterpriseReportingOpened = isOpened;
    },
    setEnterpriseReportingUrl(state, value) {
      state.enterpriseReportingUrl = value;
    },
    setUseAnimations(state, useAnimations) {
      const entries = Object.entries(state.animationSwitch);
      entries.forEach((a) => { a[1] = useAnimations; });
      state.animationSwitch = Object.fromEntries(entries);
    }
  },
  actions: {
    async notifyMapMounted() {
      // console.log('map placehoder ready');
    },
    async changeFilterContent(context, activeFilter) {
      context.commit('setIsEnterpriseReportingOpened', false);
      if (context.getters.isFilterPanelOpened && context.getters.filterPanelContent === activeFilter) {
        context.commit('setIsFilterPanelOpened', false);
        context.commit('setFilterPanelContent', null);
      } else {
        context.commit('setIsFilterPanelOpened', true);
        context.commit('setFilterPanelContent', activeFilter);
      }
    },
    async changeDetailsPanelType(context, { selectedEntity }) {
      if (selectedEntity) {
        context.commit('setIsDetailsPanelOpened', true);
        const { entityType } = selectedEntity;
        if (Object.values(UnitTypes).includes(entityType)) context.commit('setDetailsPanelType', detailsPanelTypes.unit);
        else if (Object.values(RefmodelTypes).includes(entityType) && entityType !== RefmodelTypes.DrillPattern) context.commit('setDetailsPanelType', detailsPanelTypes.refModel);
        else if (Object.values(PointTypes).includes(entityType)) context.commit('setDetailsPanelType', detailsPanelTypes.point);
        else if (entityType === PointSetType) context.commit('setDetailsPanelType', detailsPanelTypes.pointSet);
        else if (Object.values(StabilizationTypes).includes(entityType)) context.commit('setDetailsPanelType', detailsPanelTypes.hole);
        else if (entityType === DrillHoleSetType) context.commit('setDetailsPanelType', detailsPanelTypes.holeSet);
        else if (entityType === RefmodelTypes.DrillPattern) context.commit('setDetailsPanelType', detailsPanelTypes.drillPattern);
        else if (entityType === AwarenessEventSetType) context.commit('setDetailsPanelType', detailsPanelTypes.awarenessEventSet);
        else if (entityType === AwarenessEventType) context.commit('setDetailsPanelType', detailsPanelTypes.awarenessEvent);
        else if (entityType === SurfacelogType) context.commit('setDetailsPanelType', detailsPanelTypes.surfaceLog);
        else context.commit('setDetailsPanelType', null);
      } else {
        // nothing is selected
        context.commit('setIsDetailsPanelOpened', false);
        context.commit('setDetailsPanelType', null);
      }
    },
    /**
     * Sets the reduced details panel flag for reference models', surfacelogs' and units' details panels.
     * These panels are the only ones accessible without the appropriate license so they will be rendered with a smaller height.
     * @param {*} selectedEntity - The entity which's details panel is open.
     */
    async changeIsDetailsPanelReduced(context, { selectedEntity }) {
      let result = false;
      if (selectedEntity &&
        (Object.values(RefmodelTypes).includes(selectedEntity.entityType)
          || Object.values(UnitTypes).includes(selectedEntity.entityType)
          || SurfacelogType === selectedEntity.entityType)
      ) {
        const guard = contextLicenceService(withContext(selectedEntity).context);
        result = guard.restrictAll;
      }
      context.commit('setIsDetailsPanelReduced', result);
    },
    async initializeFilteringPanel(context) {
      const stabilizationLicence = licenceService.hasStabilizationLicence();
      const earthmovingLicence = licenceService.hasEarthmovingLicence();
      let component = filterPointPanelTypes.point;
      if (stabilizationLicence && !earthmovingLicence) component = filterPointPanelTypes.hole;
      context.commit('setFilterPointPanelContent', component);
    },
    async handleEntitySelection(context, selectedEntity) {
      const { newValue } = selectedEntity;
      if (!newValue) {
        context.commit('setIsDetailsPanelOpened', false);
      }
      context.commit('setIsFilterPanelOpened', false);
      context.commit('setIsDetailsPanelOpened', false);
      context.dispatch('changeDetailsPanelType', { selectedEntity: newValue });
      context.dispatch('changeIsDetailsPanelReduced', { selectedEntity: newValue });
    },
    async openEnterpriseReporting(context) {
      if (context.state.isEnterpriseReportingOpened) return;
      let url = null;
      const { projectUuid } = context.rootGetters;
      try {
        url = await getEnterpriseReportingURL(projectUuid);
      } catch (error) {
        const message = $gettext('Something went wrong, please try again later');
        context.dispatch('notifications/error', { exception: error, message }, { root: true });
        return;
      }
      context.commit('setEnterpriseReportingUrl', url);
      context.commit('setIsEnterpriseReportingOpened', true);
    },
    async closeEnterpriseReporting(context) {
      context.commit('setIsEnterpriseReportingOpened', false);
      context.commit('setEnterpriseReportingUrl', null);
    },
  }
};

export { shellStoreModule, filterPanelTypes, filterPointPanelTypes, detailsPanelTypes };