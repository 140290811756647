import { toDate, subDays, startOfDay, endOfDay, startOfMinute, format } from 'date-fns';
import translationService from '@/services/translation.service';
import api from '@/http/api';
import awarenessEventMapper from '@/http/awareness/awarenessEvent.mapper';
import licenceService from '@/services/licence.service';
import { getFileDownloadError } from '@/domain/fileDownload.error';
import { AwarenessEventFilterType } from '@/domain/awareness/enums/AwarenessEventFilterType.enum';
import { createFilter } from '@/domain/filters/filterModelFactory.ts';
import FilterAwarenessEventViewModel from '@/store/filterModule/awarenessEvents/filterAwarenessEventViewModel';
import { uniqBy } from 'lodash';
import awarenessServiceAlarmTypeFilter from '@/services/awareness.service';
import { DetectedZoneFilterValue } from '@/domain/awareness/enums/DetectedZoneFilterValue.enum';
import { AlarmTypeFilterValue } from '@/domain/awareness/enums/AlarmTypeFilterValue.enum';
import { AwarenessEventValue } from '@/domain/awareness/enums/AwarenessEventValue.enum';
import { gettext } from '@/translations/gettext.setup';
import waffleFlagService from '@/services/waffleFlag.service';

const { $gettext } = gettext;

const getAlarmStatuses = () => Object.values(AwarenessEventValue).map(value => ({ label: translationService.get(value), type: value, isSelected: false }));

const filterAwarenessEventModule = {
  state: {
    startDate: null,
    endDate: null,
    selectedAlarmTypes: [],
    selectedUnitTypes: [],
    selectedUnitNames: [],
    selectedDetectedZones: [],
    selectedAlarmStatuses: [],
    resultAwarenessEventSet: [],
    isQueryOngoing: false,
    isExportLoading: false,
  },
  getters: {
    filters(state) {
      const filters = [];
      if (state.selectedAlarmTypes.length) filters.push(createFilter(AwarenessEventFilterType.AlarmType, state.selectedAlarmTypes));
      if (state.selectedUnitTypes.length) filters.push(createFilter(AwarenessEventFilterType.UnitType, state.selectedUnitTypes));
      if (state.selectedUnitNames.length) filters.push(createFilter(AwarenessEventFilterType.UnitName, state.selectedUnitNames));
      if (state.selectedDetectedZones.length) filters.push(createFilter(AwarenessEventFilterType.DetectedZone, state.selectedDetectedZones));
      if (state.selectedAlarmStatuses.some(alarmStatus => alarmStatus.isSelected)) {
        filters.push(createFilter(
          AwarenessEventFilterType.AlarmStatus,
          state.selectedAlarmStatuses.filter(alarmStatus => alarmStatus.isSelected).map(alarmStatus => alarmStatus.type)
        ));
      }
      return filters;
    },
    startDate(state) {
      return state.startDate;
    },
    endDate(state) {
      return state.endDate;
    },
    /**
     * Provides view model for Alarm Type filter.
     * Uses UI-specific Alarm Type semantics.
     * Should provide all the values, without exceptions.
     */
    availableAlarmTypes() {
      const alarmTypes = AlarmTypeFilterValue;
      const alarmTypeKeys = Object.keys(alarmTypes);

      const isBukaEnabled = waffleFlagService.isBukaEnabled();
      const result = alarmTypeKeys
        // filter out BUKA alarm types if BUKA is not yet released
        .filter(key => (isBukaEnabled ? true : alarmTypes[key] !== AlarmTypeFilterValue.BUKACone && alarmTypes[key] !== AlarmTypeFilterValue.BUKAPerson && alarmTypes[key] !== AlarmTypeFilterValue.BUKAVehicle))
        .map(key => ({ id: alarmTypes[key], name: translationService.get(awarenessEventMapper.mapAlarmType(alarmTypes[key])) }));
      return awarenessServiceAlarmTypeFilter(result, 'id');
    },
    availableUnitTypes(state, getters, rootState, rootGetters) {
      const uniqueTypeUnits = uniqBy(rootGetters['app/allUnits'], 'entityType');
      return uniqueTypeUnits.map(unit => ({ id: unit.entityType, name: translationService.get(unit.entityType) }));
    },
    availableUnitNames(state, getters, rootState, rootGetters) {
      const availableUnits = rootGetters['app/allUnits'];
      return availableUnits.map(unit => ({ id: unit.uuid, name: unit.name }));
    },
    /**
     * Provides view model for Detected Zone filter.
     * Uses UI-specific Detected Zone semantics.
     * Should provide all the values, except Undefined zone.
     */
    availableDetectedZones() {
      const detectedZones = DetectedZoneFilterValue;
      const detectedZoneKeys = Object.keys(detectedZones);
      return detectedZoneKeys
        .map(key => ({ id: detectedZones[key], name: translationService.get(awarenessEventMapper.mapDetectedZone(detectedZones[key])) }))
        .filter(viewModel => viewModel.id !== detectedZones.UndefinedZone);
    },
    selectedAlarmTypes(state) {
      return state.selectedAlarmTypes;
    },
    selectedUnitTypes(state) {
      return state.selectedUnitTypes;
    },
    selectedUnitNames(state) {
      return state.selectedUnitNames;
    },
    selectedDetectedZones(state) {
      return state.selectedDetectedZones;
    },
    selectedAlarmStatuses(state) {
      return state.selectedAlarmStatuses;
    },
    resultAwarenessEventSet(state) {
      return state.resultAwarenessEventSet;
    },
    isQueryOngoing(state) {
      return state.isQueryOngoing;
    },
    isExportLoading(state) {
      return state.isExportLoading;
    }
  },
  mutations: {
    setStartDate(state, startDate) {
      state.startDate = toDate(startDate);
    },
    setEndDate(state, endDate) {
      state.endDate = toDate(endDate);
    },
    setSelectedAlarmTypes(state, alarmTypes) {
      state.selectedAlarmTypes = alarmTypes;
    },
    setSelectedUnitTypes(state, unitTypes) {
      state.selectedUnitTypes = unitTypes;
    },
    setSelectedUnitNames(state, unitNames) {
      state.selectedUnitNames = unitNames;
    },
    setSelectedDetectedZones(state, detectedZones) {
      state.selectedDetectedZones = detectedZones;
    },
    setSelectedAlarmStatuses(state, alarmStatuses) {
      state.selectedAlarmStatuses = alarmStatuses;
    },
    setSelectedAlarmStatus(state, { index, value }) {
      state.selectedAlarmStatuses[index].isSelected = value;
    },
    setResultAwarenessEventSet(state, awarenessEventSet) {
      state.resultAwarenessEventSet = awarenessEventSet;
    },
    setQueryOngoing(state, isQueryOngoing) {
      state.isQueryOngoing = isQueryOngoing;
    },
    setIsExportLoading(state, isExportLoading) {
      state.isExportLoading = isExportLoading;
    },
    clearSelectedAlarmStatuses(state) {
      state.selectedAlarmStatuses = getAlarmStatuses();
    },
    clearFilters(state) {
      const now = new Date(Date.now());
      state.startDate = startOfDay(subDays(now, 1));
      state.endDate = endOfDay(now);
      state.selectedAlarmTypes = [];
      state.selectedUnitTypes = [];
      state.selectedUnitNames = [];
      state.selectedDetectedZones = [];
      state.selectedAlarmStatuses = getAlarmStatuses();
      state.resultAwarenessEventSet = [];
      state.isQueryOngoing = false;
      state.isExportLoading = false;
    }
  },
  actions: {
    async initializeFilters(context) {
      if (licenceService.hasAwarenessLicence()) {
        const now = new Date(Date.now());
        context.commit('setStartDate', startOfDay(subDays(now, 1)));
        context.commit('setEndDate', startOfMinute(endOfDay(now)));
        context.commit('setSelectedAlarmStatuses', getAlarmStatuses());
      }
    },
    async filterAwarenessEvents(context) {
      context.commit('setQueryOngoing', true);
      context.commit('setResultAwarenessEventSet', []);
      const { projectUuid } = context.rootGetters;
      const { startDate, endDate, filters } = context.getters;
      const startTime = startDate.toISOString();
      const endTime = endDate.toISOString();
      const metadataFields = ['all'];
      try {
        const eventSet = await api.filterAwarenessEvents(
          projectUuid,
          startTime,
          endTime,
          filters,
          metadataFields
        );
        if (eventSet.count > 0) {
          const language = gettext.current;

          const resultEventSet = new FilterAwarenessEventViewModel(eventSet, language);
          context.commit('setResultAwarenessEventSet', [resultEventSet]);
          if (!eventSet.exceedsPerformanceLimit) await context.dispatch('map/addEntities', { entities: [eventSet] }, { root: true });
        } else {
          context.commit('setResultAwarenessEventSet', []);
        }
      } catch (e) {
        context.dispatch('notifications/error', { exception: e, message: $gettext('Unable to filter awareness events. Please try again later.') }, { root: true });
      } finally {
        context.commit('setQueryOngoing', false);
      }
    },
    async exportAwarenessEvents(context) {
      try {
        context.commit('setIsExportLoading', true);
        const { projectUuid } = context.rootGetters;
        const [appliedEventSet] = context.getters.resultAwarenessEventSet;
        const { originTimeRange, originFilters } = appliedEventSet.data;
        const startTime = originTimeRange.from.toISOString();
        const endTime = originTimeRange.to.toISOString();
        const extension = 'csv';
        const fileName = `ConX_Awareness_Events_${format(new Date(Date.now()), 'yyyy-MM-dd_hh:mm:ss')}.${extension}`;
        await api.exportAwarenessEvents(
          projectUuid,
          startTime,
          endTime,
          originFilters,
          fileName,
          extension
        );
      } catch (e) {
        await context.dispatch('notifications/error', getFileDownloadError(e), { root: true });
      } finally {
        context.commit('setIsExportLoading', false);
      }
    },
    async downloadPowerBiTemplate(context) {
      try {
        context.commit('setIsExportLoading', true);
        const { projectUuid } = context.rootGetters;
        await api.fetchPoweBiTemplate(projectUuid);
      } catch (e) {
        await context.dispatch('notifications/error', getFileDownloadError(e), { root: true });
      } finally {
        context.commit('setIsExportLoading', false);
      }
    }
  },
};

export default filterAwarenessEventModule;
