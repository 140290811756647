import { computed } from 'vue';
import waffleFlagService from '@/services/waffleFlag.service';
import { useStore } from 'vuex';

export default function useWaffleFlag() {
  // Computed
  const store = useStore();
  const projectInitialized = computed(() => store.getters['app/projectInitialized']);

  const isLiveModeEnabled = computed(() => {
    if (!projectInitialized.value) return false;
    return waffleFlagService.isLiveModeEnabled();
  });
  const unitLocationsFromTickService = computed(() => {
    if (!projectInitialized.value) return false;
    return waffleFlagService.unitLocationsFromTickService();
  });
  const isOddjobEnabled = computed(() => {
    if (!projectInitialized.value) return false;
    return waffleFlagService.isOddjobEnabled();
  });
  const isMoneyPennyEnabled = computed(() => {
    if (!projectInitialized.value) return false;
    return waffleFlagService.isMoneyPennyEnabled();
  });
  const isEditedAndDeletedMeasuredPointsEnabled = computed(() => {
    if (!projectInitialized.value) return false;
    return waffleFlagService.isEditedAndDeletedMeasuredPointsEnabled();
  });
  const filteringSurfacesByPointCodes = computed(() => {
    if (!projectInitialized.value) return false;
    return waffleFlagService.filteringSurfacesByPointCodes();
  });
  const showJobsInConX = computed(() => {
    if (!projectInitialized.value) return false;
    return waffleFlagService.showJobsInConX();
  });
  const showEnterpriseReporting = computed(() => {
    if (!projectInitialized.value) return false;
    return waffleFlagService.showEnterpriseReporting();
  });
  const isBukaEnabled = computed(() => {
    if (!projectInitialized.value) return false;
    return waffleFlagService.isBukaEnabled();
  });

  return {
    isLiveModeEnabled,
    unitLocationsFromTickService,
    isOddjobEnabled,
    isMoneyPennyEnabled,
    isEditedAndDeletedMeasuredPointsEnabled,
    filteringSurfacesByPointCodes,
    showJobsInConX,
    showEnterpriseReporting,
    isBukaEnabled
  };
}